import { FC, memo } from 'react';

import { Link } from '@common/atoms/Link';
import { PlatformID } from '@common/clients/api';
import { MissingPlatformID, PlatformLogo, PositionVariant } from '@web/atoms/PlatformLogo/PlatformLogo';

import styles from './PlatformLogoList.module.scss';

export const PLATFORM_URLS = {
    [PlatformID.BR]: 'https://www.bright.nl',
    [PlatformID.GP]: 'https://www.gpblog.com/nl',
    [PlatformID.VI]: 'https://www.vi.nl',
    [PlatformID.VN]: 'https://www.voetbalnieuws.nl',
    [PlatformID.VP]: 'https://www.voetbalprimeur.nl',
    [MissingPlatformID.BB]: 'https://www.babybytes.nl',
};

type PlatformEntry = [PlatformID | MissingPlatformID, string];

interface Props {
    currentPlatform: PlatformID;
}

const Component: FC<Props> = ({ currentPlatform }) => {
    const platformUrlEntries = Object.entries(PLATFORM_URLS) as Array<PlatformEntry>;
    const excludeCurrentPlatform = platformUrlEntries.filter(
        ([platformID]) => platformID !== currentPlatform,
    );

    return (
        <ul className={styles.PlatformLogoList}>
            {excludeCurrentPlatform.map(([platformID, url], index) => (
                <li key={`platform-logo-${index}`} className={styles['list-item']}>
                    <Link href={url}>
                        <PlatformLogo positionVariant={PositionVariant.FOOTER} platformID={platformID} />
                    </Link>
                </li>
            ))}
        </ul>
    );
};

Component.displayName = 'PlatformLogoList';
export const PlatformLogoList = memo(Component);
